// custom typefaces
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/900.css"
import "@fontsource/merriweather"
// normalize CSS across browsers
import "./src/normalize.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

// custom CSS styles
import "./src/style.css"

// for equations
require(`katex/dist/katex.min.css`)
